*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden !important;
  touch-action: manipulation;
  margin: 0;
}

#root {
  min-height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  background: #ffe474;
}

.setting {
  background: #c9c9c9 !important;
}
